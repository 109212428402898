/* eslint-disable no-mixed-spaces-and-tabs */
import React, { FC, useMemo } from 'react';
import { H6, Label2, Label3, Label4 } from '@pixiepkg/react';
import { SCREEN_SIZES, useWindowSize } from '@shared/hooks/useWindownSize';
import { Profile } from '@shared/models/Profile.model';
import { sessionAtom } from '@shared/stores/session.store';
import { useAtom } from 'jotai';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import Link from 'next/link';

import styles from './style/ExpertCard.module.scss';
import Skeleton from 'react-loading-skeleton';
import { toast } from 'react-toastify';
import { loginModal } from '@shared/stores/loginModal.store';
import classNames from 'classnames';
import { putCommaMoney } from '@shared/utils/money';
import { getFullName } from '@shared/utils/name';
import { getTooltipProps } from '@shared/constant/tooltip';
import { useRouter } from 'next/router';
import { useImageBlurProps } from '@shared/hooks/useImageBlurProps';

type Props = {
	expert?: Profile;
	loading?: boolean;
	openNewTab?: boolean;
	onClick?: (id: string) => void;
	onClickBookNow?: (id: string) => void;
	clickedFrom?:
		| 'homepage'
		| 'expert_recommend'
		| 'before_webinar'
		| 'in_webinar'
		| 'after_webinar'
		| 'about'
		| 'about_expert_recommend';
};

export const ExpertCardV2: FC<Props> = React.memo(
	({ expert, loading, openNewTab, onClick, onClickBookNow, clickedFrom }) => {
		const blurProps = useImageBlurProps();
		const router = useRouter();
		const { width } = useWindowSize();
		const [session] = useAtom(sessionAtom);
		const [, setIsOpenLogin] = useAtom(loginModal);
		const { t } = useTranslation('common');
		const locale = router.locale;
		const handleOnClickExpert = () => expert?.id && onClick && onClick(expert?.id);
		const expertPrice =
			expert?.expert?.prices && expert?.expert?.prices.length > 0
				? expert?.expert?.prices[0].price
				: undefined;

		const _domains: string[] =
			expert?.expert?.domains?.map((_domain) => {
				if (typeof _domain === 'string') {
					return `${_domain}`;
				}

				return locale === 'en' ? `${_domain.domainEN}` : `${_domain.domainVN}`;
			}) ?? [];

		const Label = width > SCREEN_SIZES.xs ? Label3 : Label4;

		const last_company = useMemo(() => {
			const exeSorted =
				expert?.expert?.experiences?.sort((a: any, b: any) => b.startedAt - a.startedAt) ??
				[];
			const index = exeSorted.findIndex((item) => !item.endedAt);
			if (index !== -1) return exeSorted[index];
			else return exeSorted[0];
		}, [expert?.expert?.experiences]);

		return (
			<div
				className={'overflow-hidden rounded-[12px] bg-white xs:mb-5'}
				key={expert?.id}
				onClick={handleOnClickExpert}
			>
				<Link
					href={`/experts/${expert?.id}${clickedFrom ? `?from=${clickedFrom}` : ''}`}
					passHref
					legacyBehavior
				>
					<div className={'relative cursor-pointer'}>
						{loading ? (
							<Skeleton count={1} height={335} width={335} />
						) : (
							<div className={'relative h-[335px] min-w-[335px]'}>
								<Image
									src={
										expert?.avatar ||
										'https://pixie-avatars.s3.ap-southeast-1.amazonaws.com/default-avatar.jpeg'
									}
									layout={'fill'}
									objectFit="cover"
									alt="avatar"
									loading="lazy"
									{...blurProps}
								/>
							</div>
						)}

						{expert?.expert?.mode === 'donation' && (
							<div className="absolute left-[20px] top-[16px] rounded-xl bg-green-500 px-2 py-1">
								<Label4 className="text-center text-white">
									Free Session Extra
								</Label4>
							</div>
						)}

						{expert?.expert?.mode !== 'donation' ? (
							<div className="absolute left-[20px] top-[16px]"></div>
						) : (
							<div className="absolute left-[20px] top-[44px]"></div>
						)}
						<div
							className={classNames(
								'text-2-lines absolute bottom-0 left-0 right-0 flex h-[100px] items-end px-[20px] pb-[20px]',
								styles.topicBackground
							)}
						>
							{expert?.expert?.topics && expert?.expert?.topics?.length > 0 ? (
								<Label2 color={'#fff'} semiBold>
									{expert.expert.topics[0].name}
								</Label2>
							) : (
								<Label2 color={'#fff'} semiBold>
									{t('expertCard.consultationInField')}{' '}
									{_domains.map((item, index) =>
										index !== _domains.length - 1 ? `${item}, ` : `${item}`
									)}
								</Label2>
							)}
						</div>
					</div>
				</Link>
				{/* Kinh nghiem */}
				<div className={'flex min-h-[201px] flex-col px-[18px] py-[16px]'}>
					<div className={'flex-1'}>
						<div className={'flex items-center justify-between'}>
							<div className={'mr-[8px] flex w-full max-w-[145px] flex-1 flex-col'}>
								<Label4 className={'text-[12px]'} semiBold color={'#6E7C8B'}>
									{t('expertCard.consultedBy')}
								</Label4>
								{loading ? (
									<Skeleton count={1} height={30} width={195} />
								) : (
									<Link
										href={`/experts/${expert?.id}${
											clickedFrom ? `?from=${clickedFrom}` : ''
										}`}
										passHref
										legacyBehavior
									>
										<a
											target={openNewTab ? '_blank' : '_self'}
											onClick={handleOnClickExpert}
										>
											<H6
												bold
												className={
													'line-[22px] leading-[22px] hover:text-brand-700'
												}
											>
												{getFullName(expert?.lastName, expert?.firstName)}
											</H6>
										</a>
									</Link>
								)}
							</div>
							<div className={'cursor-default border-l-2 pl-[8px] text-right'}>
								{expert?.expert?.mode === 'donation' ? (
									<>
										<Label2
											className={' text-[12px] line-through'}
											semiBold
											color={'#6E7C8B'}
										>
											{putCommaMoney(expertPrice, 'đ')}
										</Label2>
										<div
											className="flex items-center"
											{...getTooltipProps('Giá chưa bao gồm VAT')}
										>
											<Label2 className={' text-[16px]'} semiBold>
												{putCommaMoney(0, 'đ')}
											</Label2>
										</div>
									</>
								) : (
									<div
										className="flex items-center"
										{...getTooltipProps('Giá chưa bao gồm VAT')}
									>
										<Label2 className={'text-[16px]'} semiBold>
											{putCommaMoney(expertPrice, 'đ')}
										</Label2>
									</div>
								)}
							</div>
						</div>
						{loading ? (
							<Skeleton className="mt-1" count={1} height={18} width={195} />
						) : (
							<div
								{...getTooltipProps(expert?.expert?.headline || '')}
								className={'cursor-default'}
							>
								<Label3
									color={'#6E7C8B'}
									semiBold
									className={classNames(
										'mt-[4px] max-w-[295px]',
										styles.lineClamp
									)}
								>
									{expert?.expert?.headline || ''}
								</Label3>
							</div>
						)}
					</div>
					<div className={classNames('mt-[12px] flex items-center justify-between')}>
						{last_company && last_company.company && last_company.company.logo && (
							<div style={{ height: '24px', width: '100px', position: 'relative' }}>
								<Image
									src={
										last_company?.company?.logo ??
										'https://pixie-avatars.s3.ap-southeast-1.amazonaws.com/default-avatar.jpeg'
									}
									objectFit="contain"
									objectPosition={'left'}
									alt="logo"
									fill={true}
									loading="lazy"
									// {...blurProps}
								/>
							</div>
						)}
						{loading ? (
							<Skeleton className="mt-1" count={1} height={18} width={195} />
						) : (
							<Label4 semiBold color={'#6E7C8B'} className={'text-right'}>
								+{expert?.expert?.extra?.yoe ?? 1}{' '}
								{t('expertCard.yearsOfExperience')}
							</Label4>
						)}
					</div>
					{/*Button booking*/}

					<button
						className={
							'mt-[12px] w-full rounded-[8px] bg-[#E8ECFF] py-[8px] text-[14px] font-[600] text-[#444CFF]'
						}
						disabled={session?.user?.id === expert?.id}
						// className="z-10"
						onClick={(e) => {
							e.stopPropagation();
							e.preventDefault();
							onClickBookNow && onClickBookNow(expert?.id || 'fake_id');

							if (session?.accessToken && session.isFetched && session.isSignedIn) {
								router.push(
									`/experts/${expert?.id}/booking${
										clickedFrom ? `?from=${clickedFrom}` : ''
									}`
								);
							} else {
								toast.error('You need to login first!');
								setIsOpenLogin({
									isOpen: true,
									from: 'book_now'
								});
							}
						}}
					>
						<Label color={'#444CFF'}>{t('field.bookNow')}</Label>
					</button>
				</div>
			</div>
			// </Link>
		);
	}
);

ExpertCardV2.displayName = 'ExpertCardV2';
